import React, { useContext } from "react";
import './corporate-event.scss';
import { AppContext } from '../../services/translationContext';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from "@mui/material";

export const CorporateEvent = () => {
  const { services: { TranslationsService } } = useContext(AppContext);
  
  const { instance } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  document.title = 'Sydea X Perience';

  const signOut = () => {
    instance.logoutRedirect();
  };

  const signIn = () => {
    instance.loginRedirect().catch((error) => console.log(error));
  };

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <div className='section-home position-relative'>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" style={{backgroundColor:'#141414'}}>
            <Toolbar className='justify-content-between'>
              <IconButton variant="outlined" style={{color:'#ffffff', borderColor:'#ffffff'}} className="me-3 showMobile">
                <Link to='/sydea-hub' className="text-deco-none" style={{color:'#ffffff'}}>
                  <ArrowBackIosIcon/>
                </Link>
              </IconButton>
                {/* <Button variant="outlined" startIcon={<ArrowBackIosIcon />} style={{color:'#ffffff', borderColor:'#ffffff'}} className="me-3 showDesktop">
                  <Link to='/sydea-hub' className="text-deco-none" style={{color:'#ffffff'}}>
                    <span className='px-1'>Hub</span>
                  </Link>
                </Button> */}
                
                <Link to='/sydea-hub' className="text-deco-none showDesktop" style={{color:'#ffffff'}}>
                  <Button variant="outlined" startIcon={<ArrowBackIosIcon />} style={{color:'#ffffff', borderColor:'#ffffff'}} className="me-3">
                    <span className='px-1'>Hub</span>
                  </Button>
                </Link>

                <div className='d-flex gap-3 align-items-center'>
                {
                activeAccount && 
                  <div>
                    <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
                      <p className='fw-bold text-uppercase m-0 fs-sm-6 fs-md-5 p-0' style={{lineHeight:'normal'}}>{activeAccount.name}</p>
                      <p className='m-0 p-0 fs-6' style={{lineHeight:'normal'}}>{activeAccount.username}</p>
                    </Typography>
                  </div>
                }
                <div>
                  {
                    activeAccount ?
                    (
                      <IconButton aria-label="delete" style={{color:'#ffffff'}} onClick={signOut}>
                        <LogoutIcon />
                      </IconButton>
                    )
                    :
                    (
                      <IconButton aria-label="delete" style={{color:'#ffffff'}} onClick={signIn}>
                        <LoginIcon />
                      </IconButton>
                    )
                  }
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
        <div style={{height:'60px'}}></div>
        <p>{JSON.stringify(activeAccount)}</p>
        </div>
    </MsalAuthenticationTemplate>
  );
};